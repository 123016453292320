@import '../base/settings';


    .centraldiv {
        .imgcontent {
            grid-area: imgcontent;
            display: flex;
            flex-direction: column;
            color: $title_text;
            .tabscontainer {
                height: 500px;
                width: 700px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .cuitDescription {
        display: flex;
        flex-direction: column;
        align-content: left;
        background-color: #f0f0f0;
        border-radius: 0 0 10px 10px;
        margin-top: 0px;
        padding: 10px;
        padding-left: 20px;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
        .separator {
            background-color: #e5e5e5;
            height: 1px;
            width: 90%;
        }
    }
    .select-cuit-desc-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    .btnLogin{
        
        
        display: flex;
        justify-items: flex-end;
        justify-content: flex-end;
        align-self: flex-end;
        height: 50%;
        min-width: 110px;
    }

    .react-code-input input{
        padding: 0 !important;
        text-align: center;
    }

    .banner-box{
        background-color: #092F57;
        background-image: url('../../assets/background-validation.svg');
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 15%;
        padding: 100px 30px 30px;
        min-height: 400px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px !important;
    }
    .banner-box h1 {
        font-size: 2.5em;
        font-weight: normal;
        line-height: 1.3em;
        padding-right: 40%;
    }
    .banner-box h1 b{
        font-weight: bold;
    }
    @media (max-width: 1600px) {
        .banner-box{
            padding: 90px 30px 30px;
            background-size: 25%;
            margin-bottom: 40px !important;
        }
    }

    @media (max-width: 768px) {
        .banner-box{
            margin: 0;
            padding: 90px 30px 30px;
            background-size: 40%;
            min-height: unset;
            justify-content:flex-start;
            margin-bottom: 10px !important;
        }
        .banner-box h1 {
            font-size: 1.6em;
            padding-right: 20%;
        }
    }

