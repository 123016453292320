@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Open sans', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  max-width: 90vw;
}

.alert-email {
  font-weight: 500;
  font-family: 'system-ui';
}

.logo {
  width: 150px;
}

@media only screen and (max-device-width: 600px) {
  .logo {
    width: 130px;
    margin-top: 5px;
  }
}
