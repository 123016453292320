//Colores
$primary_background: #ffffff;
$primary: #203ae9 !important ;
$secondary: #203ae9 !important ;
$background-secondary: #092f57;
$darkerGray: #212121;
$darkGray: #515151;
$black: #000000;
$lightBlue: #00a1da;
$gray: #9b9b9b;
$linkBlue: #203ae9;
$darkBlue: #092f57;
//Textos
$title_text: #203ae9 !important;

// Botones
$button_text_v1: white;
$button_active_v1: #1462b6;
$button_hover_v1: #061d36;

$marginLeft: 7%;
$marginTop: 0;

// Opacidad imagenes
$opacity_images: 0.5;

//Backgrounds
$backgroundMobile: #ffffff;
$backgroundDesktop: #ffffff;
$backgroundError: #ffb3b3;
$backgroundInfo: #d4edf8;
