@font-face {
    font-family: 'Titillium Web Bold';
    src: local('TitilliumWeb-Bold'), url(../../assets/fonts/TitilliumWeb-Bold.ttf) format('truetype');
}

* p {
    font-family: 'Helvetica', sans-serif !important;
    font-weight: 400;
}
* b {
    font-weight: 600;
}

* h1 {
    font-family: 'Titillium Web', sans-serif !important;
    color: #092f57;
    font-size: 1.8rem;
}

* h2 {
    font-family: 'Titillium Web', sans-serif !important;
}

* h3 {
    font-weight: 400;
}

* h4 {
    color: #092f57;
    font-family: 'Titillium Web', sans-serif !important;
    font-size: 1.2rem;
    font-weight: '500';
}
.sub2 {
    font-size: 20px;
    color: #092f57;
    font-weight: 400;
}
.uppercase {
    text-transform: 'uppercase';
}

//Dinamic Fonts

@media (max-width: 600px) {
    //Mobile
    .d-h1 {
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
    .d-h2 {
        font-size: 20px;
    }
    .d-h3 {
        font-size: 20px;
    }
    .d-h4 {
        font-size: 16px;
    }
    .d-h5 {
        font-size: 18px;
    }
}
@media (min-width: 600px) and (max-width: 1024px) {
    // Tablet
    .d-h1 {
        font-size: 30px;
    }
    .d-h2 {
        font-size: 26px;
    }
    .d-h3 {
        font-size: 18px;
    }
    .d-h4 {
        font-size: 16px;
    }
    .d-h5 {
        font-size: 16px;
    }
}
@media (min-width: 1024px) {
    //Desktop
    .d-h1 {
        font-size: 38px;
    }
    .d-h2 {
        font-size: 26px;
    }
    .d-h3 {
        font-size: 18px;
    }
    .d-h4 {
        font-size: 22px;
    }
    .d-h5 {
        font-size: 22px;
    }
}
